
import { Vue, Component, Prop } from 'vue-property-decorator'

import $app from '@/plugins/modules'
import { Common } from '@/common'
import { System } from '@/modules/system'
import { IViewAction, Allign, ActionStyle } from '@/modules/system/types'

@Component
export default class BasePage extends Vue {
  @Prop({ default: () => [] })
  readonly actions!: Array<IViewAction>;

  @Prop({ default: 'center' })
  readonly actionsAlign!: Allign

  @Prop({ default: 'flat' })
  readonly actionsStyle!: ActionStyle

  @Prop(String)
  readonly caption: string | undefined;

  @Prop({ default: false })
  readonly loading!: boolean;

  @Prop({ default: false })
  readonly flat!: boolean;

  @Prop({ default: false })
  readonly fluid!: boolean | string;

  common = $app.module(Common);
  system = $app.module(System);

  updateEnv() {
    this.common.$store.appSetViewTitle(this.caption ? $app.i18n(this.caption) : '');
  }

  updated() {
    this.updateEnv();
  }

  created() {
    this.updateEnv();
  }
}
